import React from 'react'

import SEO from '../components/seo'
import { BookList, Section, Body, Heading, Container } from 'components'

const IndexPage = () => {


  return (
    <>
      <SEO title="Home" keywords={['vce study guides']} />
      <Section textAlign="center" backgroundColor="#F9FAFB">
        <Container maxWidth="sm">
          <Heading mb={3}>Learn Anywhere. Anytime</Heading>
          <Body mb={5}>
            Comprehensive study guides packed full of interactive content, all
            wrapped up in convenient eBooks.
          </Body>
        </Container>
        <Container maxWidth="md">
          <BookList />
        </Container>
      </Section>
    </>
  )
}



export default IndexPage
